<template>
  <div id="home">
    <van-nav-bar
        title="登陆"
        fixed
    />
    <van-image class="a-us" :src="banner"></van-image>
    <div class="login">
      <div class="r-s rs-login">
        <h3>登录/注册<span>LOGIN / REGISTRATION</span></h3>
        <div class="u-login">
          <h4>欢迎来到源古叁生！</h4>
          <div class="u-pre">中国（+86)</div>
          <input type="number" v-model="loginform.account" placeholder="请输入您的手机号码">
          <input type="password" v-model="loginform.password" placeholder="请输入密码">
          <van-button :loading="isbusy" @click.stop="login">立即登陆</van-button>
          <div class="u-reg">还没有账号？<span @click.stop="goback('/register')">立即注册</span></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import {onMounted, reactive, toRefs} from 'vue';
import  {httppost} from "@/libs/api";
import {useStore} from "vuex";
import { useRouter } from 'vue-router'
import {Toast} from "vant";
import {useI18n} from "vue-i18n";

export default {
  name: 'Home',
  setup() {
    const store=useStore();
    const router = useRouter();
    const state = reactive({
      isbusy:false,
      banner: require('@/assets/default/imgs/aboutus.jpg'),
      loginform:{
        account:'',
        password:''
      },
    })
    const login = async () => {
      if( !state.loginform.account ){
        Toast.fail('请输入手机号');
        return false;
      }
      if( !(/^1[345789]\d{9}$/.test(state.loginform.account)) ){
        Toast.fail('手机号不正确');
        return false;
      }
      if( !state.loginform.password ){
        Toast.fail('请输入密码');
        return false;
      }
      state.isbusy = true;
      let res = await httppost("/api/login/dologin",state.loginform);
      if( res.code == 0 ){
        store.commit('setMemberInfo', res.data.memberinfo);
        store.commit('setFrontToken', res.data.token);
        //state.loginData.isRemember && setRememberInfo(state.loginData.isRemember);
        setTimeout(() => {
          router.push({path: '/'}).catch(err => {err});
        }, 500);
        state.isbusy = false;
      } else {
        state.isbusy = false;
        Toast.fail({
          message:res.msg,
          position:'center'
        });
        return false;
      }
    }
    onMounted(() => {
    })

    return {
      ...toRefs(state),
      login
    };
  },
}
</script>
